import { RulesModelInteface } from "./rulesModelInterface";
import { action } from "easy-peasy";

const rulesModel: RulesModelInteface = {
  rules: [],
  setRules: action((state, payload) => {
    state.rules = payload;
  }),
  addRule: action((state, payload) => {
    payload.id = 0;
    state.rules.push(payload);
  }),
  removeRule: action((state, payload) => {
    state.rules.splice(payload, 1);
    state.rules.forEach((rule, index) => {
      rule.index = index;
    });
  }),
  removeRuleByFieldId: action((state, payload) => {
    state.rules = state.rules.filter((rule) => rule.agent !== payload && rule.target !== payload);
  }),
  updateRule: action((state, payload) => {
    state.rules[payload.index] = payload;
  }),
  moveRuleUp: action((state, payload) => {
    const index = payload;
    if(index>0){
      const ruleList = state.rules.map((rule, index) => ({ ...rule, index }));
      const indexAbove = index - 1;
      const rule = ruleList[index];
      ruleList[index] = ruleList[indexAbove];
      ruleList[indexAbove] = rule;
      state.rules = ruleList;
    }
  }),
  moveRuleDown: action((state, payload) => {
    const index = payload;
    if(index < state.rules.length-1){
      const ruleList = state.rules.map((rule, index) => ({ ...rule, index }));
      const indexBelow = index + 1;
      const rule = ruleList[index];
      ruleList[index] = ruleList[indexBelow];
      ruleList[indexBelow] = rule;
      state.rules = ruleList;
    }
  }),
  setRulesOrderIndexes: action((state, payload) => {
    state.rules.forEach((rule, index) => {
      rule.orderIndex = index;
    });
  }),
};

export default rulesModel;
