import "./styles/MyFormListItemActions.scss";

import React, { useState } from "react";

import CopyToClipboard from "react-copy-to-clipboard";
import { Form } from "../interface/Form";
import { Icon } from "@material-ui/core";
import { KeyValue } from "../../../repositories/interfaces";
import { generateLinkToDB } from "../util/util";
import { useNavigate } from "react-router-dom";
import { useStoreState } from "../../../store/hooks";
import { useTranslation } from "react-i18next";
import { ACCESS_DENIED_ROUTE, ErrorCodes, FORM_NOT_FOUND_ROUTE } from "../../../shared/util/constants";

export interface MyFormListItemActionsProps {
  selectForm: (payload: { form: Form; t: Function }) => Promise<void>;
  form: Form;
  visibility: KeyValue;
  setVisibility: (keyValue: KeyValue) => void;
  onDelete: (form: Form) => void;
  onExport: (form: Form) => void;
  onDuplicate: (form: Form) => void;
}

const MyFormListItemActions = (props: MyFormListItemActionsProps) => {
  const { t } = useTranslation();
  const userEmail = useStoreState((state) => state.auth.userData?.unique_name!);
  const { selectForm, setVisibility, visibility, form, onDelete, onExport, onDuplicate } = props;
  const isVisible = visibility.key === form.formId.toString() && visibility.value;
  const [copySuccess, setCopySuccess] = useState(false);
  const navigate = useNavigate();
  
  const goTo = async (route: string) => {
    try
    {
      await selectForm({ form, t });
      navigate(route);
    }
    catch(error: any)
    {
      const isAccessDenied = error?.response?.status === ErrorCodes.FORBIDDEN;      
      const isFormNotFound = error?.response?.status === ErrorCodes.NOT_FOUND;      
      if (isAccessDenied) {
        navigate(ACCESS_DENIED_ROUTE);
      } 
      if (isFormNotFound) {
        navigate(FORM_NOT_FOUND_ROUTE);
      }
    }
  };
  
  const handleExport = async () => {
    try {
      await onExport(form);
    } catch (error: any) {
      const isAccessDenied = error?.response?.status === ErrorCodes.FORBIDDEN;
      const isFormNotFound = error?.response?.status === ErrorCodes.NOT_FOUND;
      if (isAccessDenied) {
        navigate(ACCESS_DENIED_ROUTE);
      } if (isFormNotFound) {
        navigate(FORM_NOT_FOUND_ROUTE);
      }
    }
  };
  return (
    <div className="actions">
      {meetBallsIcon()}
      <div className={`actions-tools`} style={{ display: isVisible ? "flex" : "none" }}>
        <div className="icon-button-atom" />
        {backButtonIcon()}
        <span className="separator-actions" /> {editFormIcon()}
        {editFormRulesIcon()}
        {editFormUsersIcon()}
        {editFormLanguagesIcon()}
        {copyPowerBILinkIcon()}
        {downloadFormIcon()}
        {deleteFormIcon()}
        {duplicateFormIcon()}
        <div className="icon-button-atom" />
      </div>
    </div>
  );

  function meetBallsIcon() {
    return (
      <Icon
        style={{ display: isVisible ? "none" : "block" }}
        onClick={() => {
          setVisibility({ key: form.formId.toString(), value: true });
        }}>
        more_horiz
      </Icon>
    );
  }

  function backButtonIcon() {
    return (
      <Icon
        onClick={() => {
          setVisibility({ key: form.formId.toString(), value: false });
        }}>
        chevron_left
      </Icon>
    );
  }

  function editFormIcon() {
    return (
      <span className="menu-item">
        <Icon onClick={() => goTo(`/Builder/Constructor/${form.formId}`)}>edit</Icon>
        <div className="tooltip-action tooltip-action-builder">
          <span className="tooltip-action-text">{t("actions.form")}</span>
        </div>
      </span>
    );
  }

  function editFormRulesIcon() {
    return (
      <span className="menu-item">
        <Icon onClick={() => goTo(`/Builder/Rules/${form.formId}`)}>settings</Icon>
        <div className="tooltip-action tooltip-action-rules">
          <span className="tooltip-action-text">{t("actions.rules")}</span>
        </div>
      </span>
    );
  }

  function editFormUsersIcon() {
    return (
      <span className="menu-item">
        <Icon onClick={() => goTo(`/Builder/UsersAssociation/${form.formId}`)}>person_add</Icon>
        <div className="tooltip-action tooltip-action-users">
          <span className="tooltip-action-text">{t("actions.users")}</span>
        </div>
      </span>
    );
  }

  function editFormLanguagesIcon() {
    return (
      <span className="menu-item">
        <Icon onClick={() => goTo(`/Builder/Globalization/${form.formId}`)}>public</Icon>
        <div className="tooltip-action tooltip-action-language">
          <span className="tooltip-action-text">{t("actions.languages")}</span>
        </div>
      </span>
    );
  }

  function copyPowerBILinkIcon() {
    return (
      <span className="menu-item">
        <CopyToClipboard
          text={generateLinkToDB(form)}
          onCopy={() => {
            setCopySuccess(true);
            setTimeout(function () {
              setCopySuccess(false);
            }, 4000);
          }}>
          <button className="clipboard-button">
            <Icon>link</Icon>
          </button>
        </CopyToClipboard>
        {copySuccess && (
          <div className="icon-button-atom clipboard-success">
            <span className="triangle"></span>
            <span className="content">{t("messages.link_copied")}</span>
          </div>
        )}
        {!copySuccess && (
          <div className="tooltip-action tooltip-action-powerbi">
            <span className="tooltip-action-text">{t("actions.power_bi")}</span>
          </div>
        )}
      </span>
    );
  }

  function downloadFormIcon() {
    return (
      <span className="menu-item">
        <Icon
          onClick={() => {
            handleExport();
            setVisibility({ key: form.formId.toString(), value: false });
          }}>
          get_app
        </Icon>
        <div className="tooltip-action tooltip-action-export">
          <span className="tooltip-action-text">{t("actions.export")}</span>
        </div>
      </span>
    );
  }

  function deleteFormIcon() {
    const ownerEmail = form.ownerEmail;

    return ownerEmail.toLowerCase() === userEmail.toLowerCase() ? (
      <span className="menu-item">
        <Icon onClick={() => onDelete(form)}>delete</Icon>
        <div className="tooltip-action tooltip-action-default">
          <span className="tooltip-action-text">{t("actions.delete")}</span>
        </div>
      </span>
    ) : null;
  }

  function duplicateFormIcon() {
    return (
      <span className="menu-item">
        <Icon onClick={() => onDuplicate(form)}>file_copy</Icon>
        <div className="tooltip-action tooltip-action-default">
          <span className="tooltip-action-text">{t("actions.duplicate")}</span>
        </div>
      </span>
    );
  }
};

export default MyFormListItemActions;
