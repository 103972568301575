import React from "react";
import "./BuilderPage.scss";

import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../../../store/hooks";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../feature/form/interface/Form";
import HeaderBuilderScreen from "../../organisms/HeaderBuilderScreen/HeaderBuilderScreen";
import { ACCESS_DENIED_ROUTE, ErrorCodes, FORM_NOT_FOUND_ROUTE } from "../../../util/constants";

interface BuilderScreenProps {}

const BuilderPage = (props: BuilderScreenProps) => {
  const { t } = useTranslation();
  const { formId } = useParams();
  let form = useStoreState((state) => state.form.form);
  const navigate = useNavigate();
  const selectForm = useStoreActions((actions) => actions.form.selectForm);

  const getLanguages = useStoreActions((actions) => actions.languages.getItems);

  const emptyFormWithFormId = mapToForm(formId);

  const formTraslation = {
    form: emptyFormWithFormId,
    t: t
  };
  if (!form) {
    getForm(selectForm, getLanguages, formTraslation, navigate);
  } else {
    return (
      <>
        <span id="inner-header">
          <HeaderBuilderScreen />
        </span>
        <span id="inner-body">
          <Outlet />
        </span>
      </>
    );
  }
  return null;
};

async function getForm(
  selectForm: any,
  getLanguages: any,
  formTraslation: any,
  navigate: Function
) {
  getLanguages("Cultures").then(() => {
    selectForm(formTraslation)
      .then(() => {
        return (
          <>
            <span id="inner-header">
              <HeaderBuilderScreen />
            </span>
            <span id="inner-body">
              <Outlet />
            </span>
          </>
        );
      })
      .catch((error: any) => {
        const isAccessDenied = error?.response?.status === ErrorCodes.FORBIDDEN;
        if (isAccessDenied) {
          navigate(ACCESS_DENIED_ROUTE);
        } else {
          navigate(FORM_NOT_FOUND_ROUTE);
        }
      });
  });
}

function mapToForm(formId: string | undefined): Form {
  return {
    formId: Number(formId),
    title: "",
    formCode: "",
    description: "",
    defaultLanguage: "",
    ownerEmail: "",
    formSections: [],
    enable: true,
    version: "",
    formUsers: [],
    formCoOwners: [],
    createdAt: "",
    formLocales: [],
    formRules: [],
    maintainUpdatedAt: true
  };
}

export default BuilderPage;
