export default {
  "common.cancel": "Annuler",
  "common.delete": "Supprimer",
  "common.duplicate": "Doubler",
  "common.title": "Titre",
  "common.upload": "Télécharger",
  "common.create": "Créer",
  "common.file": "Fichier",
  "common.section": "Section",
  "common.add": "Ajouter",
  "common.continue": "Continuer",
  "common.confirm": "Confirmer",
  "common.save": "Sauvegarder",
  "common.saved": "Sauvegardé",
  "common.yes": "Oui",
  "common.no": "Non",
  "common.skip": "Ignorer",
  "common.ok": `D'accord`,
  "common.success": "Succès",
  "common.sign_out": "Déconnexion",
  "common.answer": "Réponse",
  "common.rule": "Règle",

  "constructor.by.equipment": "Filtrer par équipement",
  "constructor.by.customer": "Filtrer par client",

  "message.createAnswer.success": "Réponse enregistrée avec succès !",
  "message.createAnswer.error.answer": "Erreur lors de la création de la réponse",
  "message.createAnswer.error.image": "Erreus lors de la création des images",
  "message.createAnswer.error.image.type": "Type d'image invalide",
  "message.getAnswers.error.invalidInput":
    "Veuillez saisir une période maximale de X jours pour filtrer les réponses.",
  "message.getAnswers.error.answer": "Erreur lors de l'analyse de AnswerData",
  "message.getAnswers.error.image": "Erreus lors de l'analyse des images",
  "message.formValidation.missingTitle": 'Veuillez remplir le champ "Titre".',
  "message.formValidation.missingEmail": 'Veuillez remplir le champ "e-mail".',
  "message.formValidation.missingSectionTitle": "Veuillez remplir le titre de toutes les sections.",
  "message.formValidation.missingFieldTitle": "Veuillez remplir le titre de tous les champs.",
  "message.formValidation.missingFieldType": "Veuillez sélectionner le type de tous les champs.",
  "message.formValidation.missingFieldId": "Veuillez mettre un identifiant pour tous les champs.",
  "message.formValidation.missingEquipmentMrk":
    "Veuillez sélectionner un équipement MRK sur le champ équipement.",
  "message.formValidation.missingSection":
    "Un formulaire a besoin d'au moins une section pour fonctionner correctement.",
  "message.formValidation.multipleCustomerField":
    'Le formulaire ne peut avoir qu\'un seul champ "Client SAR".',
  "message.formValidation.missingCustomerField":
    "Pour créer un formulaire avec un équipement SAR, veuillez mettre un champ de type SAR Client avant.",
  "message.formValidation.missingEquipmentField":
    "Pour créer un formulaire avec la campagne/raison de la fin SAR, veuillez mettre un champ de type Équipement SAR avant.",
  "message.formValidation.wrongCustomerFieldPositionAfterEquipment":
    'Le champ "Client SAR" doit être saisi avant le champ "équipement SAR"',
  "message.formValidation.wrongCustomerFieldPositionAfterCampaign":
    'Le champ "Client SAR" doit être saisi avant le champ "campagne SAR"',
  "message.formValidation.wrongCustomerFieldPositionAfterEndingReason":
    'Le champ "Client SAR" doit être saisi avant le champ "Raison de Fin SAR"',

  "message.formValidation.wrongEquipmentFieldPositionAfterCampaign":
    "L'équipement SAR doit être entré avant la campagne SAR",
  "message.formValidation.wrongEquipmentFieldPositionAfterEndingReason":
    "L'équipement SAR doit être saisi avant la raison de fin SAR",
  "message.formValidation.wrongEquipmentFieldPosition":
    "L'équipement SAR doit être placé avant la campagne SAR.",
  "message.formValidation.failureValidatingRules": "Failure validating form rules.",
  "message.formValidation.missingTargetOptionsInRules":
    "Question options are missing in form rules.",
  "message.createForm.success": "Formulaire enregistré avec succès !",
  "message.createForm.error":
    "Il n'est pas possible d'enregistrer le formulaire. Essayez à nouveau.",
  "message.updateForm.success": "Formulaire enregistré avec succès.",
  "message.updateForm.error": "Erreur lors de l'enregistrement du formulaire",
  "message.toggleFormEnable.success.enable": "Formulaire activé avec succès !",
  "message.toggleFormEnable.success.disable": "Formulaire désactivé avec succès !",
  "message.toggleFormEnable.error": "Erreur lors de l'activation/désactivation du formulaire.",
  "message.hasAnswers": "Le formulaire a des réponses.",
  "message.hasNoAnswers": "Le formulaire n'a pas de réponses.",
  "message.hasAnswers.error":
    "Erreur lors de la vérification de la présence de réponses dans le formulaire",
  "message.deleteForm.success": "Formulaire supprimé avec succès.",
  "message.deleteForm.error": "Erreur lors de la suppression de formulaire.",
  "message.duplicate.modal.title": "Sous quel nom souhaitez-vous enregistrer votre copie?",
  "message.duplicateForm.success": "Formulaire doublé avec succès.",
  "message.duplicateForm.error": "Erreur lors du doublement de formulaire.",
  "message.getMrk.error": "Erreur lors de l'obtention du MRK",
  "message.getCulture.error": "Langue non soutenue",

  "messages.oops": "Oups",
  "messages.link_copied": "Lien copié",
  "messages.confirm_delete":
    "Êtes-vous sûr de vouloir supprimer {{formTitle}} ? Cette action ne peut pas être annulée",
  "messages.confirm_delete_answers":
    "Ce formulaire a déjà des réponses. En procédant ainsi, toutes les réponses seront supprimées et cette action ne peut plus être annulée. Voulez-vous la poursuivre ?",
  "messages.confirm_title_duplicate": "Il sera envoyé en haut de la liste avec le nom ci-dessous:",
  "messages.required_fields": "Veuillez remplir tous les champs obligatoires",
  "messages.error_support": `Un problème est survenu lors de l'exécution de cette action. Si l'erreur persiste, veuillez contacter notre support.`,
  "messages.error_field_id": `S'il vous plaît, vérifiez l'ID du champ !`,
  "messages.error_field_weight": "Veuillez vérifier les champs de poids !",
  "messages.error_all_field_weight": "Veuillez remplir tous les champs de poids",
  "messages.error_fields_repeated_id": `Le champ l'ID {{fieldIdA}} de la section {{sectionIdA}} est le même que le champ l'ID {{fieldIdB}} de la section {{sectionIdB}}.`,
  "messages.error_section_repeated_id": "Il y a des sections avec le même Id !",
  "messages.error_section_name": "Veuillez vérifier le nom de la section !",
  "messages.error_section_id": `Veuillez vérifier l'ID de la section !`,
  "messages.error_section_repeated_name": "Il existe des sections portant le même nom !",
  "messages.error_duplicate_section": "Vous ne pouvez pas dupliquer une section sans champs. Créez plutôt une nouvelle section.",
  "messages.error_mrk": "Pas de MRK trouvé",
  "messages.construction":
    "Cette section est en cours de construction pour améliorer encore plus votre expérience",
  "messages.languages_note": `Si vous laissez un champ vide, il sera affiché comme la langue par défaut de l'utilisateur`,
  "messages.users_not_found": "Utilisateurs non trouvés",
  "messages.error_type_other_id": "L'ID que vous avez tapé est déjà utilisé. Tapez-en un autre.",
  "messages.confirm_rules": "Voulez vous mettre en place des règles pour ce formulaire?",
  "messages.empty_rule": "Si vous avez une règle conditionnelle, elle peut être créée ici",
  "messages.empty_section": `Sélectionnez un champ pour l'ajouter dans la section`,
  "messages.saved":
    "Votre formulaire a été sauvegardé avec succès ! Vous pouvez le vérifier maintenant sur Mes formulaires.",
  "messages.sign_out": "Vous voulez vous déconnecter du système ?",
  "messages.cannot_change_form_status":
    "Vous ne pouvez pas activer ou désactiver ce formulaire puisque vous êtes copropriétaire.",
  "pages.my_forms": "Mes formulaires",
  "pages.new_form": "Nouveau formulaire",
  "pages.feedback": "Commentaire",
  "pages.builder": "Constructeur",
  "pages.rules": "Règles",
  "pages.languages": "Langue",
  "my_forms.id": "ID",
  "my_forms.form_code": "Code du formulaire",
  "my_forms.creation_date": "Date de création",
  "my_forms.updated_date": "Dernières modifications",
  "my_forms.enable": "Activé",
  "my_forms.owner": "Propriétaire",
  "my_forms.actions": "Actions",
  "actions.form": "Modifier le formulaire",
  "actions.rules": "Modifier les règles",
  "actions.users": "Modifier les utilisateurs",
  "actions.languages": "Modifier la langue",
  "actions.power_bi": "Lien vers Power BI",
  "actions.export": "Exporter le formulaire",
  "actions.delete": "Supprimer le formulaire",
  "actions.duplicate": "Doubler le formulaire",
  "new_form.import": "Importer un nouveau formulaire",
  "new_form.creation_language": "Langage de création",
  "languages.portuguese": "Portugais",
  "languages.english": "Anglais",
  "languages.spanish": "Espagnol",
  "languages.german": "Allemand",
  "languages.mandarin": "Mandarin",
  "languages.french": "Français",
  "languages.turkish": "Turc",
  "languages.czech": "Tchèque",
  "languages.polish": "Polonais",
  "languages.slovenian": "Slovène",
  "languages.swedish": "Suédois",
  "languages.kosovo_albanian": "Albanais",
  "fields.text": "Texte court",
  "fields.textarea": "Texte long",
  "fields.number": "Numérique",
  "fields.datetime": "Date et heure",
  "fields.photo": "Photo",
  "fields.dropdown": "Liste déroulante",
  "fields.check_list": "Checklist",
  "fields.single_option": "Option unique",
  "fields.customer": "Client",
  "fields.equipment": "Équipement",
  "fields.campaign": "Campagne",
  "fields.end_reason": "Raison de la fin",
  "fields.qr_code": "Qr/Code barre",
  "fields.signature_qr": "Signature Code QR",
  "fields.boolean": "Option unique",
  "fields.dropdown-campaign": "Campagne",
  "fields.dropdown-campaign-end-reason": "Raison de la fin",
  "fields.dropdown-customer": "Client",
  "fields.dropdown-equipment": "Équipement",
  "fields.pictureFile": "Photo",
  "fields.qrbarcode": "Qr/Code barre",
  "fields.signature-qrcode": "Signature Code QR",
  "constructor.fields": "Domaines",
  "pages.users": "Utilisateurs",
  "pages.usersAndCoOwners": "Utilisateurs et copropriétaires",
  "constructor.basic": "Basique",
  "constructor.rhim": "Intégration RHIM",
  "constructor.section_title": "Titre de la section",
  "constructor.unsaves_changes": "Changements non sauvegardés",
  "constructor.mrk": "Équipement MRK",
  "constructor.preferences": "Préférences",
  "preferences.placeholder": "Choisissez un champ pour éditer ses préférences",
  "preferences.checklistValue": "Nombre maximal d’options cochées",
  "preferences.required": "Obligatoire",
  "preferences.score": "Score",
  "preferences.options": "Options",
  "preferences.automatic": "Automatique",
  "preferences.automatic_id": "Id Automatique",
  "preferences.field_id": "Id du champ",
  "preferences.field_weight": "Poids du champ",
  "preferences.section_weight": "Poids du champ",
  "preferences.section_id": "Id de la section",
  "preferences.options_placeholder": "Saisissez les articles en les séparant avec Enter",
  "preferences.mrk_placeholder": "Choisissez un MRK",
  "preferences.max": "Maximum",
  "preferences.min": "Minimum",
  "preferences.formDescription": "Use as form description",
  "preferences.typeQrOrBarCode": "Type",
  "preferences.IntegerOrFloat": "Type",
  "preferences.decimalPlacesMin" : "Nombre minimum de décimales",
  "preferences.DecimalPlaces": "DecimalPlaces",
  "preferences.option_integer": "Entier",
  "preferences.option_decimal": "Décimal",
  "preferences.error_empty_qrcode": "Choose at least one option",
  "add_options.option": "Option",
  "add_options.weight": "Poids",
  "add_options.response": "Réponse",
  "add_options.add_na": "Ajouter N/A",
  "construction.go_back": "Retour",
  "languages.notes": "Remarques",
  "users.email": "Email des utilisateurs",
  "coOwners.email": "Email des copropriétaires",
  "users.multiple_association":
    "Association multiple (remplir avec une liste de diffusion avec point-virgule comme séparateur)",
  "users.finish": "Terminer le formulaire",

  "globalization.title": "Écrivez le titre du formulaire en",
  "globalization.section": "Écrivez le titre de la section",
  "globalization.field": "Écrivez le nom du champ",
  "globalization.field.description": "Écrivez la description de votre champ",
  "globalization.option": "Écrivez la valeur de l'option",

  "pagination.view": "Affichage {{from}}-{{to}} de {{count}}",
  "pagination.rows_per_page": "Lignes par page:",
  "messages.error_form_access_denied":
    "Vous n'avez pas accès à ce formulaire. Veuillez rechercher un formulaire dont vous êtes l'auteur ou le co-auteur",
  "messages.page_not_found": "Page non trouvée",
  "messages.text_page_not_found": "Désolé, la page à laquelle vous tentez d'accéder n'existe pas.",
  "messages.go_back_my_forms": "Retour à Mes Formulaires",
  "messages.access_denied": "Accès refusé",
  "messages.text_access_denied":
    "Vous n'avez pas accès à ce formulaire. Veuillez rechercher un formulaire dont vous êtes l'auteur ou le co-auteur.",
  "messages.form_not_found": "Formulaire introuvable",
  "messages.text_form_not_found":
    "Désolé, le formulaire auquel vous essayez d'accéder n'existe pas.Veuillez rechercher un autre formulaire ou en créer un nouveau.",
  "message.formValidation.multipleEquipmentInSection":
    "Le formulaire ne peut avoir qu'un seul champ Équipement SAR par section",
  "preferences.description": "Description",
  "messages.fieldsInformDescriptionPreference":
    "You have already selected {{numberOfFieldsInDescription}}/{{MAXIMUM_FIELDS_IN_DESCRIPTION}} fields",
  "messages.error_field_no_options": "Veuillez vérifier les options du champ!",
  "messages.error_single_option_no_options":
    "Vous ne pouvez pas enregistrer un seul champ d'option sans options.",
  "messages.max_filled_as_form_description": "To select this field, please unselect other field",
  "messages.tooltip_decimal_minimum_alert":
    "En raison des 2 décimales, la limite minimale de caractères pour les champs décimaux est de 3.",
  "message.form_last_update": "Dernière actualisation",
  "message.form_last_update_by": "Par"
};
