import "./SectionPreference.scss";

import { InputOnlyNumber, TitleColumn, Toggle } from "../../atoms";
import React, { useEffect, useMemo, useState } from "react";
import { useStoreActions, useStoreState } from "../../../../store/hooks";

import { FormSectionLocaleModel } from "../../../../models/Section/FormSectionLocaleModel";
import { IdPreference } from "../../molecules";
import { LanguageItem } from "../../../../feature/languages/interface/LanguageItem";
import { Section } from "../../../../models/Section/Section";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";

interface SectionPreferenceProps {
  section: Section;
}

const SectionPreference = (props: SectionPreferenceProps) => {
  const { t } = useTranslation();
  const { section } = props;
  const sections = useStoreState((state) => state.sections.items);
  const updateSection = useStoreActions((actions) => actions.sections.updateSection);
  const updateIsSaved = useStoreActions((actions) => actions.form.updateIsSaved);
  const languages = useStoreState((state) => state.languages.items);
  const defaultLanguage = useStoreState((state) => state.form.form?.defaultLanguage);
  const formRules = useStoreState((state: any) => state.rules.rules);
  const updateRule = useStoreActions((action: any) => action.rules.updateRule);  

  const [initialId, setInitialId] = useState(section.sectionId);
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setInitialId(section.sectionId);
  }, [section.sectionId]);

  const isIdAlreadyUsed = (newId: string) => {
    return sections.some((sec: Section) => 
      sec.sectionId === newId && sec.uuid !== section.uuid
    );
  };

    const debounceFunction = useMemo(() => debounce((newId) => {
      if(isIdAlreadyUsed(newId)){
        setInitialId(section.sectionId);
        setErrorMessage(t('messages.error_type_other_id'));

        setTimeout(() => {
          setErrorMessage('');
        }, 2000);
      } else {
        setInitialId(newId);

        const updatedSection: Section = {
          ...section,
          sectionId: newId
        };

        Object.values(formRules).forEach((rule: any) => {
          if(rule.target === section.sectionId) {
              Object.assign(rule, { target: newId });
              updateRule(rule);
          }
          if(rule.agent === section.sectionId) {
            Object.assign(rule, { agent: newId });
            updateRule(rule);
          }
        });
        
        updateSection(updatedSection);
        updateIsSaved(false);
        setErrorMessage('');
      }
    }, 1000), [sections, section]);

  function changeSectionId(newId: string) {
    setInitialId(newId);
    debounceFunction(newId);
  }

  const updateSectionTitle = (newTitle: string) => {
    let cultureIdDefaultLanguage = languages.find(
      (language: LanguageItem) => language.isoCode === defaultLanguage
    )?.id;
    let localeToUpdate = section.formSectionLocales.find(
      (locale: FormSectionLocaleModel) => locale.cultureId === cultureIdDefaultLanguage
    );
    if (localeToUpdate) {
      localeToUpdate.title = newTitle;
    }
    const updatedSection: Section = {
      ...section,
      title: newTitle
    };
    updateSection(updatedSection);
    updateIsSaved(false);
  };

  return (
    <>
      <section className="sectionPreferenceTitle">
        <div className="titlePreferenceTitle">
          <TitleColumn text={t("common.title")} />
        </div>
        <input
          maxLength={200}
          type="text"
          id="inputTitlePreference"
          value={t(section.title).toString()}
          onChange={(event) => {
            updateSectionTitle(event.target.value);
            updateIsSaved(false);
          }}
        />
      </section>
      <IdPreference
        title={t("preferences.section_id")}
        onChangeFunction={changeSectionId}
        preference={initialId}
        errorMessage={errorMessage}
      />
      <section className="sectionPreferenceTitle">
        <div className="scorePreferenceTitle">
          <TitleColumn text={t("preferences.score")} />
          <Toggle
            isSelected={section.score}
            changeToggleValue={() => {
              let isScored = !section.score;
              if (!isScored) {
                section.weight = 0;
              }
              const updatedScore: Section = {
                ...section,
                score: !section.score
              };
              updateSection(updatedScore);
              updateIsSaved(false);
            }}
          />
        </div>
      </section>
      {section.score && (
        <section className="sectionPreferenceTitle">
          <div className="sessionWeightPreferenceTitle">
            <TitleColumn text={t("preferences.section_weight")} />
            <InputOnlyNumber
              id="inputSessionWeightPreference"
              className="sessionWeight"
              maxLength={200}
              value={section.weight}
              onChange={(value?: number) => {
                const updatedWeight: Section = {
                  ...section,
                  weight: value
                };
                updateSection(updatedWeight);
                updateIsSaved(false);
              }}
            />
          </div>
        </section>
      )}
    </>
  );
};

export default SectionPreference;
