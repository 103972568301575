import "./IdPreference.scss";

import React from "react";
import { TitleColumn } from "../../atoms";
import { useTranslation } from "react-i18next";

interface ChangeIdPreferenceProps {
  title: string;
  onChangeFunction: (preference: string) => void;
  preference: string;
  errorMessage: string;
}

const ChangeIdPreference = (props: ChangeIdPreferenceProps) => {
  const { t } = useTranslation();
  const { title, onChangeFunction, preference } = props;

  function removeInvalidChars(str: string) {
    str = str.replace(/[^0-9a-zA-Z_\u00C0-\u00FF]+/g, "");
    return str;
  }

  return (
    <section id="IdPreference">
      <div className="IdPreferenceTitle">
        <TitleColumn text={title} />
      </div>
      <input
        maxLength={200}
        type="text"
        id="inputIdPreference"
        value={preference}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          let newId = removeInvalidChars(event.target.value);
          onChangeFunction(newId);
        }}
        placeholder={t("preferences.automatic_id")}
        style={{ border: props.errorMessage ? '1px solid red' : undefined }}
      />
      {props.errorMessage && <p className="error-message" style={{ color: 'red', margin: '5px 0 0' }}>{props.errorMessage}</p>}
    </section>
  );
};

export default ChangeIdPreference;

