import { Icon, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Cell, RhimCell, RhimDialog, RhimTable } from "rhim-ui";
import { MaxLengthTextField, Toggle } from "../../../shared/components/atoms";
import SubHeader from "../../../shared/components/molecules/SubHeader";
import { useStoreState } from "../../../store/hooks";
import { useModalVisibility } from "../../modal";
import { ModalType } from "../../modal/enum/ModalType";
import { Modal } from "../../modal/interface/Modal";
import { form_has_answers } from "../FormManager/FormManager";
import { download } from "../FormUtil";
import { Form } from "../interface/Form";
import MyFormListItemActions from "./MyFormListItemActions";
import "./styles/MyFormsPage.scss";

export interface MyFormsPageProps {
  forms: Form[];
  getForms: () => void;
  selectForm: (payload: { form: Form; t: Function }) => Promise<void>;
  getForm: (formId: number) => Promise<Form>;
  toggleActive: (form: Form) => void;
  duplicateForm: (formId: number, formTitle: string) => Promise<void>;
  deleteForm: (formId: number) => void;
}

const MyFormsPage = (props: MyFormsPageProps) => {
  const { t } = useTranslation();
  const { getForms, selectForm, forms, toggleActive, getForm, duplicateForm, deleteForm } = props;

  useEffect(() => {
    getForms();
  }, []);

  const { modalProperties, setModalProperties } = useModalVisibility({} as Modal);
  const userData = useStoreState((state) => state.auth.userData);

  const INITIAL_STATE_ACTIONS_VISIBILITY = { key: "", value: false };
  const [actionsVisibility, setActionsVisibility] = useState(INITIAL_STATE_ACTIONS_VISIBILITY);
  const [isOpenedModal, setIsOpenedModal] = useState(false);
  const [formTitleToDuplicate, setFormTitleToDuplicate] = useState("");

  const formTitleToDuplicateRef = useRef(formTitleToDuplicate);

  const [isFormTitleValid, setIsFormTitleValid] = useState(false);

  const isFormTitleValidRef = useRef(isFormTitleValid);

  useEffect(() => {
    isFormTitleValidRef.current = isFormTitleValid;
  }, [isFormTitleValid]);

  useEffect(() => {
    formTitleToDuplicateRef.current = formTitleToDuplicate;
    var isFormTitleNullOrEmpty = !formTitleToDuplicateRef.current || formTitleToDuplicateRef.current.trim() === "";
    var isFormTitleBiggerThanOneHundredCaracteres = formTitleToDuplicateRef.current.length > 100;
    setIsFormTitleValid(!isFormTitleNullOrEmpty && !isFormTitleBiggerThanOneHundredCaracteres);
  }, [formTitleToDuplicate]);

  const handleDelete = (form: Form) => {
    setModalProperties({
      message: t("messages.confirm_delete", { formTitle: form.title }),
      type: ModalType.Error,
      confirmText: t("common.delete").toUpperCase(),
      async onConfirmFunction() {
        await checkFormAnswers(form.formId);
      }
    } as Modal);
    setIsOpenedModal(true);
  };

  const checkFormAnswers = async (formId: number) => {
    const res = await form_has_answers(formId);
    if (!res) {
      executeDeleteForm(formId);
    } else {
      setModalProperties({
        message: t("messages.confirm_delete_answers"),
        type: ModalType.Error,
        confirmText: t("common.delete").toUpperCase(),
        onConfirmFunction() {
          executeDeleteForm(formId);
        }
      } as Modal);
      setIsOpenedModal(true);
    }
  };

  const executeDeleteForm = (formId: number) => {
    setIsOpenedModal(false);
    deleteForm(formId);
  };

  const handleDuplicate = (form: Form) => {
      setModalProperties({
        title: t("message.duplicate.modal.title"),
        message: t("messages.confirm_title_duplicate", { formTitle: form.title }),
        type: ModalType.Info,
        confirmText: t("common.duplicate").toUpperCase(),
        onConfirmFunction() {
          if (!isFormTitleValidRef.current) return;
          setActionsVisibility(INITIAL_STATE_ACTIONS_VISIBILITY);
          setIsOpenedModal(false);
          duplicateForm(form.formId, formTitleToDuplicateRef.current).then(getForms);
        }
      } as Modal);
      getFormTitleOnDuplicate(form.title);
      setIsOpenedModal(true);
  };

  const handleExport = async (form: Form) => {
      const formExtended = await getForm(form.formId);
      const isFormOrSectionsNotFound = !formExtended || !formExtended.formSections
      if(isFormOrSectionsNotFound)
        return;
      const formToExport = {
        ...formExtended,
        formId: undefined,
        formUsers: undefined,
        formCoOwners: undefined
      };
      formToExport.formSections.forEach((sec: any) => {
        sec.form = undefined;
        sec.formFields.forEach((field: any) => {
          field.form = undefined;
          field.options.forEach((option: any) => {
            option.id = undefined;
          });
        });
      });
      download(JSON.stringify(formToExport), `${formToExport.title}.json`, "text/json");
  };

  const headCells = [
    {
      key: "title",
      sortable: true,
      label: t("common.title"),
      cellProps: { className: "titleColumn" }
    },
    {
      key: "formId",
      sortable: true,
      label: t("my_forms.id"),
      cellProps: { className: "titleColumn" }
    },
    {
      key: "formCode",
      sortable: true,
      label: t("my_forms.form_code"),
      cellProps: { className: "titleColumn" }
    },
    {
      key: "createdAt",
      sortable: true,
      label: t("my_forms.creation_date"),
      cellProps: { className: "titleColumn" }
    },
    {
      key: "updatedAt",
      sortable: true,
      label: t("my_forms.updated_date"),
      cellProps: { className: "titleColumn" }
    },
    {
      key: "enable",
      sortable: false,
      label: t("my_forms.enable"),
      cellProps: { className: "titleColumn" }
    },
    {
      key: "owner",
      sortable: false,
      label: t("my_forms.owner"),
      cellProps: { className: "titleColumn" }
    },
    {
      key: "actions",
      sortable: false,
      label: t("my_forms.actions"),
      cellProps: { className: "titleColumn" }
    }
  ];

  const mapHeadCells = (headCells: any[]) => {
    return headCells.map((headCell) => {
      return {
        ...headCell,
        label: <article>{t(headCell?.label)}</article>
      };
    });
  };

  const isDuplicateModal = modalProperties.title === t("message.duplicate.modal.title");

  const DuplicateFormInput = (
    <MaxLengthTextField
      maxLength={100}
      value={formTitleToDuplicate}
      onValueChange={setFormTitleToDuplicate}
      inputRedAlert={!isFormTitleValid}
    />
  );

  const getContentOfActionsModal = () => {
    return (
      <>
        <p className="modal-text">{modalProperties.message}</p>
        {isDuplicateModal && DuplicateFormInput}
      </>
    );
  };

  const getFormTitleOnDuplicate = (formTitle : string) => {
    const regex = /^(.*) - copy(\((\d+)\))?$/;
    const [, baseName, , textCopyNumber] = formTitle.match(regex) ?? [];
    const newName = baseName ?? formTitle;
    const newCopyNumber = textCopyNumber ? parseInt(textCopyNumber) + 1 : 1;
    const newSufix = baseName ? ` - copy(${newCopyNumber})` : ' - copy';
    setFormTitleToDuplicate(`${newName}${newSufix}`);
};

  const showOrHideTooltipCoOwner = (form: Form) => {
    const isOwner = form.ownerEmail === userData?.unique_name;

    return (
      <Tooltip arrow title={t("messages.cannot_change_form_status")} disableHoverListener={isOwner}>
        <span>
          <Toggle
            disabled={!isOwner}
            isSelected={form.enable}
            changeToggleValue={() => toggleActive(form)}
          />
        </span>
      </Tooltip>
    );
  };

  const createDateObjectCell = (keyOfFormData: string) => {
    return {
      key: keyOfFormData,
      renderDefaultCell: false,
      renderCell: (form: Form) => {
        const dateValue = form[keyOfFormData];
        const indexOfColumnDateOnTableHeader = headCells.findIndex(
          (cell) => cell.key === keyOfFormData
        );
        const uniqueKey = `${dateValue}-${indexOfColumnDateOnTableHeader}`;
        const date: Date = new Date(dateValue);
        return (
          <RhimCell
            key={uniqueKey}
            cellValue={date.toLocaleDateString()}
            cellProps={{ className: "date" }}
          />
        );
      }
    };
  };

  const cells: Array<Cell> = [
    {
      key: "title",
      renderDefaultCell: true
    },
    {
      key: "formId",
      renderDefaultCell: true,
      cellProps: { className: "date" }
    },
    {
      key: "formCode",
      renderDefaultCell: true,
      cellProps: { className: "date" }
    },
    createDateObjectCell("createdAt"),
    createDateObjectCell("updatedAt"),
    {
      key: "enable",
      renderDefaultCell: false,
      cellProps: { style: { padding: 5 } },
      renderCell: (form: Form) => {
        return (
          <RhimCell
            key={`switch-${form.formId}`}
            cellValue={showOrHideTooltipCoOwner(form)}
            cellProps={{ className: "date" }}
          />
        );
      }
    },
    {
      key: "owner",
      renderDefaultCell: false,
      cellProps: { style: { padding: 5 } },
      renderCell: (form: Form) => {
        const isVisible = form.ownerEmail === userData?.unique_name;
        return (
          <RhimCell
            key={`owner-${form.formId}`}
            cellValue={
              <Icon style={{ display: isVisible ? "block" : "none" }} fontSize="small">
                check_circle
              </Icon>
            }
          />
        );
      }
    },
    {
      key: "actions",
      renderDefaultCell: false,
      cellProps: { style: { padding: "5px" } },
      renderCell: (form: Form) => {
        return (
          <RhimCell
            key={`toggle-${form.formId}`}
            cellValue={
              <MyFormListItemActions
                key={`my-forms-list-item-actions-${form.formId}`}
                selectForm={selectForm}
                form={form}
                setVisibility={setActionsVisibility}
                visibility={actionsVisibility}
                onDelete={handleDelete}
                onExport={handleExport}
                onDuplicate={handleDuplicate}
              />
            }
          />
        );
      }
    }
  ];

  return (
    <>
      <section className="my-forms">
        <article className="title">
          <SubHeader
            title={t("pages.my_forms")}
            showButton={false}
            buttonLabel={t("pages.new_form")}
            isMainAction
          />
        </article>
        <article className="main">
          <Grid className="container">
            <Grid container item direction="column">
              <RhimTable
                key="forms-table"
                headCells={mapHeadCells(headCells)}
                dataRows={forms}
                cells={cells}
                tableProps={{ className: "tableRoot" }}
                paperProps={{ variant: "elevation", elevation: 0 }}
                tablePaginationProps={{
                  labelRowsPerPage: t("pagination.rows_per_page"),
                  labelDisplayedRows: ({ from, to, count }: any) =>
                    t("pagination.view", { from: from, to: to, count: count }),
                  nextIconButtonProps: { className: "navigationTableButton" },
                  backIconButtonProps: { className: "navigationTableButton" },
                  classes: {
                    caption: "navigationTableCaption",
                    selectRoot: "selectTableText",
                    selectIcon: "selectTableIcon"
                  },
                  className: "navigationTableCaption"
                }}
              />
            </Grid>
          </Grid>
        </article>

        <RhimDialog
          isMobile={false}
          title={<p className="modal-title">{modalProperties.title || t("common.delete")}</p>}
          isOpen={isOpenedModal}
          confirm={{
            title: modalProperties.confirmText ?? t("common.confirm").toUpperCase(),
            handleFunction: () => {
              if (modalProperties.onConfirmFunction) modalProperties.onConfirmFunction();
            }
          }}
          cancel={{
            title: t("common.cancel").toUpperCase(),
            handleFunction: () => setIsOpenedModal(false)
          }}
          content={getContentOfActionsModal()}
        />
      </section>
    </>
  );
};

export default MyFormsPage;
