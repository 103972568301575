export default {
    "common.cancel": "Prekliči",
    "common.delete": "Izbriši",
    "common.duplicate": "Podvoji",
    "common.title": "Naslov",
    "common.upload": "Naloži",
    "common.create": "Ustvari",
    "common.file": "Datoteka",
    "common.section": "Oddelek",
    "common.add": "Dodaj",
    "common.continue": "Nadaljuj",
    "common.confirm": "Potrdi",
    "common.save": "Shrani",
    "common.saved": "Shranjeno",
    "common.yes": "Da",
    "common.no": "Ne",
    "common.skip": "Preskoči",
    "common.ok": "Ok",
    "common.success": "Uspeh",
    "common.sign_out": "Odjava",
    "common.answer": "Odgovor",
    "common.rule": "Pravilo",

    "message.createAnswer.success": "Odgovor uspešno shranjen!",
    "message.createAnswer.error.answer": "Napaka pri ustvarjanju odgovora",
    "message.createAnswer.error.image": "Napaka pri ustvarjanju slike",
    "message.createAnswer.error.image.type": "Neveljavna vrsta slike",
    "message.getAnswers.error.invalidInput":
        "Vnesite največje obdobje {{maxDays}} dni za filtriranje odgovorov.",
    "message.getAnswers.error.answer": "Napaka pri obdelavi podatkov odgovora",
    "message.getAnswers.error.image": "Napaka pri obdelavi slik",
    "message.formValidation.missingTitle": "Prosim, izpolnite polje Naslov",
    "message.formValidation.missingEmail": "Prosim, izpolnite polje za e-pošto",
    "message.formValidation.missingSectionTitle": "Prosim, izpolnite naslove vseh odsekov",
    "message.formValidation.missingFieldTitle": "Prosim, izpolnite naslove vseh polj",
    "message.formValidation.missingFieldType": "Prosim, izberite vrsto za vsa polja",
    "message.formValidation.missingFieldId": "Prosim, vnesite ID za vsa polja.",
    "message.formValidation.missingEquipmentMrk": "Prosim, izberite opremo MRK na polju opreme",
    "message.formValidation.missingSection": "Obrazec potrebuje vsaj en odsek, da deluje pravilno",
    "message.formValidation.multipleCustomerField": "Obrazec lahko vsebuje samo eno SAR stranko",
    "message.formValidation.multipleEquipmentInSection":
        "Obrazec lahko vsebuje samo eno SAR opremo na odsek",
    "message.formValidation.missingCustomerField":
        "Za ustvarjanje obrazca s SAR opremo vnesite najprej polje SAR stranke",
    "message.formValidation.missingEquipmentField":
        "Za ustvarjanje obrazca s SAR kampanjo ali razlogom za zaključek, najprej vnesite polje SAR opreme",
    "message.formValidation.wrongCustomerFieldPositionAfterEquipment":
        "SAR stranka mora biti vnesena pred SAR opremo",
    "message.formValidation.wrongCustomerFieldPositionAfterCampaign":
        "SAR stranka mora biti vnesena pred SAR kampanjo",
    "message.formValidation.wrongCustomerFieldPositionAfterEndingReason":
        "SAR stranka mora biti vnesena pred SAR razlogom za zaključek",
    "message.formValidation.wrongEquipmentFieldPositionAfterCampaign":
        "SAR oprema mora biti vnesena pred SAR kampanjo",
    "message.formValidation.wrongEquipmentFieldPositionAfterEndingReason":
        "SAR oprema mora biti vnesena pred SAR razlogom za zaključek",
    "message.formValidation.wrongEquipmentFieldPosition":
        "SAR oprema mora biti nameščena pred SAR kampanjo",
    "message.formValidation.failureValidatingRules": "Napaka pri preverjanju pravil obrazca.",
    "message.formValidation.missingTargetOptionsInRules":
        "Manjkajo možnosti vprašanja v pravilih obrazca.",
    "message.createForm.success": "Obrazec uspešno shranjen!",
    "message.createForm.error": "Napaka pri shranjevanju obrazca. Poskusite znova.",
    "message.updateForm.success": "Obrazec uspešno shranjen.",
    "message.updateForm.error": "Napaka pri shranjevanju obrazca",
    "message.toggleFormEnable.success.enable": "Obrazec uspešno omogočen!",
    "message.toggleFormEnable.success.disable": "Obrazec uspešno onemogočen!",
    "message.toggleFormEnable.error": "Napaka pri omogočanju/onemogočanju obrazca",
    "message.hasAnswers": "Obrazec ima odgovore.",
    "message.hasNoAnswers": "Obrazec nima odgovorov.",
    "message.hasAnswers.error": "Napaka pri preverjanju, ali obrazec vsebuje odgovore",
    "message.deleteForm.success": "Obrazec uspešno izbrisan.",
    "message.deleteForm.error": "Napaka pri brisanju obrazca.",
    "message.duplicate.modal.title": "S katerim imenom želite shraniti svojo kopijo?",
    "message.duplicateForm.success": "Obrazec uspešno podvojen.",
    "message.duplicateForm.error": "Napaka pri podvajanju obrazca.",
    "message.getMrk.error": "Napaka pri pridobivanju MRK",
    "message.getCulture.error": "Nepodprti jezik",

    "messages.oops": "Ups",
    "messages.link_copied": "Povezava kopirana",
    "messages.confirm_delete":
        "Ste prepričani, da želite izbrisati {{formTitle}}? Te akcije ni mogoče razveljaviti.",
    "messages.confirm_delete_answers":
        "Ta obrazec že vsebuje odgovore. Če nadaljujete, bodo vsi odgovori izbrisani in te akcije ni mogoče razveljaviti. Ali želite nadaljevati?",
    "messages.confirm_title_duplicate": "Pošlje se na vrh seznama z naslednjim imenom:",
    "messages.required_fields": "Prosim, izpolnite vsa zahtevana polja",
    "messages.error_support":
        "Prišlo je do težave pri izvajanju te akcije. Če napaka vztraja, se obrnite na našo podporo.",
    "messages.error_field_id": "Prosim, preverite ID polja!",
    "messages.error_field_no_options": "Prosim, preverite možnosti polja!",
    "messages.error_field_preference_no_options": "Prosim, preverite nastavitve polja",
    "messages.error_qrbarcode_field_preference_no_options":
        "Ne morete shraniti polja QR/Črtne kode brez izbire vsaj ene vrste",
    "messages.error_field_dropdown.title": "Ne morete shraniti spustnega menija brez možnosti.",
    "messages.error_field_weight": "Prosim, preverite teže polj!",
    "messages.error_all_field_weight": "Prosim, izpolnite vsa polja teže!",
    "messages.error_fields_repeated_id":
        "Polje {{fieldIdA}} ID odseka {{sectionIdA}} je enako polju {{fieldIdB}} odseka {{sectionIdB}}.",
    "messages.error_section_repeated_id": "Obstajajo odseki z enakim ID-jem!",
    "messages.error_section_name": "Prosim, preverite ime odseka!",
    "messages.error_type_other_id": "ID, ki ste ga vnesli, je že v uporabi. Vnesite drugega.",
    "messages.error_section_id": "Prosim, preverite ID odseka!",
    "messages.error_section_repeated_name": "Obstajajo odseki z enakim imenom!",
    "messages.error_duplicate_section": "Ne morete podvojiti odseka brez polj. Namesto tega ustvarite nov odsek.",
    "messages.error_mrk": "Ni bilo mogoče najti MRK.",
    "messages.error_multiphotos_preferences": "Prosim, preverite nastavitve več fotografij!",
    "messages.error_invalid_preference": "Neveljavne vrednosti nastavitev",
    "messages.construction": "Ta odsek je v izgradnji za nadaljnjo izboljšavo vaše izkušnje",
    "messages.languages_note": "Če pustite prazno polje, se bo uporabniku prikazalo v privzetem jeziku.",
    "messages.users_not_found": "Ni bilo mogoče najti uporabnikov",
    "messages.confirm_rules": "Ali želite določiti pravila za ta obrazec?",
    "messages.empty_rule": "Če imate pogojno pravilo, ga lahko tukaj ustvarite",
    "messages.empty_section": "Izberite polje, da ga dodate v odsek",
    "messages.saved": "Vaš obrazec je bil uspešno shranjen! Lahko ga preverite v Moji obrazci.",
    "messages.sign_out": "Ali se želite odjaviti iz sistema?",
    "messages.cannot_change_form_status": "Ne morete omogočiti ali onemogočiti tega obrazca, saj ste so-lastnik.",
    "pages.my_forms": "Moji obrazci",
    "pages.new_form": "Nov obrazec",
    "pages.feedback": "Povratne informacije",
    "pages.builder": "Ustvarjalec",
    "pages.rules": "Pravila",
    "pages.languages": "Jeziki",
    "pages.users": "Uporabniki",
    "pages.usersAndCoOwners": "Uporabniki in solastniki",
    "my_forms.id": "ID",
    "my_forms.form_code": "Koda obrazca",
    "my_forms.creation_date": "Datum ustvarjanja",
    "my_forms.updated_date": "Zadnje spremembe",
    "my_forms.enable": "Omogoči",
    "my_forms.owner": "Lastnik",
    "my_forms.actions": "Dejanja",
    "actions.form": "Uredi obrazec",
    "actions.rules": "Uredi pravila",
    "actions.users": "Uredi uporabnike",
    "actions.languages": "Uredi jezike",
    "actions.power_bi": "Povezava Power BI",
    "actions.export": "Izvozi obrazec",
    "actions.delete": "Izbriši obrazec",
    "actions.duplicate": "Podvoji obrazec",
    "new_form.import": "Uvozi nov obrazec",
    "new_form.creation_language": "Jezik ustvarjanja",
    "languages.portuguese": "Portugalščina",
    "languages.english": "Angleščina",
    "languages.spanish": "Španščina",
    "languages.german": "Nemščina",
    "languages.mandarin": "Mandarinščina",
    "languages.french": "Francoščina",
    "languages.turkish": "Turščina",
    "languages.slovenian": "Slovenščina",
    "languages.polish": "Poljščina",
    "languages.czech": "Češčina",
    "languages.swedish": "Švedščina",
    "languages.kosovo_albanian": "Albanščina",
    "fields.text": "Kratko besedilo",
    "fields.textarea": "Dolgo besedilo",
    "fields.number": "Številčno",
    "fields.datetime": "Datum in ura",
    "fields.photo": "Fotografija",
    "fields.dropdown": "Spustni meni",
    "fields.single_option": "Enojna možnost",
    "fields.customer": "Stranka",
    "fields.equipment": "Oprema",
    "fields.campaign": "Kampanja",
    "fields.end_reason": "Razlog za zaključek",
    "fields.qr_code": "QR/Koda",
    "fields.signature_qr": "QR podpis",
    "fields.boolean": "Enojna možnost",
    "fields.dropdown-campaign": "Kampanja",
    "fields.dropdown-campaign-end-reason": "Razlog za zaključek",
    "fields.dropdown-customer": "Stranka",
    "fields.dropdown-equipment": "Oprema",
    "fields.pictureFile": "Fotografija",
    "fields.qrbarcode": "QR/Koda",
    "fields.signature-qrcode": "QR podpis",
    "fields.check_list": "Checklist",
    "constructor.fields": "Polja",
    "constructor.filter": "Filter",
    "constructor.by.equipment": "Filtriraj po opremi",
    "constructor.by.customer": "Filtriraj po stranki",
    "constructor.basic": "Osnovno",
    "preferences.checklistValue": "Največ izbranih možnosti",
    "constructor.rhim": "RHIM integracija",
    "constructor.section_title": "Naslov odseka",
    "constructor.unsaves_changes": "Neshranjene spremembe",
    "constructor.mrk": "Oprema MRK",
    "constructor.preferences": "Nastavitve",
    "preferences.placeholder": "Izberite polje za urejanje njegovih nastavitev",
    "preferences.required": "Zahtevano",
    "preferences.score": "Ocena",
    "preferences.options": "Možnosti",
    "preferences.automatic": "Samodejno",
    "preferences.automatic_id": "Samodejni ID",
    "preferences.field_id": "ID polja",
    "preferences.field_weight": "Teža polja",
    "preferences.section_weight": "Teža odseka",
    "preferences.section_id": "ID odseka",
    "preferences.options_placeholder": "Vnesite elemente, ločene z enter",
    "preferences.mrk_placeholder": "Izberite MRK",
    "preferences.max": "Največ",
    "preferences.min": "Najmanj",
    "preferences.formDescription": "Uporabi kot opis obrazca",
    "preferences.error_empty_qrcode": "Izberite vsaj eno možnost",
    "preferences.typeQrOrBarCode": "Vrsta",
    "preferences.IntegerOrFloat": "Vrsta",
    "preferences.decimalPlacesMin": "Najmanj decimalnih mest",
    "preferences.DecimalPlaces": "Decimalna mesta",
    "add_options.option": "Možnost",
    "add_options.weight": "Teža",
    "add_options.response": "Odgovor",
    "add_options.add_na": "Dodaj N/A",
    "construction.go_back": "Nazaj",
    "languages.notes": "Opombe",
    "users.email": "E-pošta uporabnika",
    "coOwners.email": "E-pošta solastnikov",
    "users.multiple_association": "Večkratna povezava (izpolnite z e-poštnim seznamom, ločenim s podpičji)",
    "users.finish": "Dokončaj obrazec",

    "globalization.title": "Napišite naslov vašega obrazca v",
    "globalization.section": "Napišite naslov odseka v",
    "globalization.field": "Napišite ime polja v",
    "globalization.field.description": "Napišite opis vašega polja v",
    "globalization.option": "Napišite vrednost možnosti v",

    "rules.if": "Če",
    "rules.status": "Status",
    "rules.response": "Odgovor",
    "rules.action": "Akcija",
    "rules.question": "Vprašanje",
    "rules.options": "Možnosti",

    "option.is": "Je",
    "option.isn't": "Ni",
    "option.hide.section": "Skrij odsek",
    "option.hide.field": "Skrij polje",
    "option.show.section": "Pokaži odsek",
    "option.show.field": "Pokaži polje",
    "option.filter": "Filter",
    "option.required": "Zahtevano",

    "pagination.view": "Prikaz {{from}}-{{to}} od {{count}}",
    "pagination.rows_per_page": "Vrstic na stran:",
    "messages.error_form_access_denied": "Nimate dostopa do tega obrazca. Poiščite obrazec, kjer ste lastnik ali solastnik",
    "messages.page_not_found": "Stran ni najdena",
    "messages.text_page_not_found": "Oprostite, stran, do katere poskušate dostopati, ne obstaja.",
    "messages.go_back_my_forms": "Nazaj na Moji obrazci",
    "messages.access_denied": "Dostop zavrnjen",
    "messages.text_access_denied": "Nimate dostopa do tega obrazca. Prosim, poiščite drug obrazec, v katerem ste avtor ali soavtor.",
    "messages.form_not_found": "Obrazec ni najden",
    "messages.text_form_not_found": "Oprostite, obrazec, do katerega poskušate dostopati, ne obstaja. Prosim, poiščite drug obrazec ali ustvarite novega.",
    "preferences.description": "Opis",
    "messages.fieldsInformDescriptionPreference": "Že ste izbrali {{numberOfFieldsInDescription}}/{{MAXIMUM_FIELDS_IN_DESCRIPTION}} polj",
    "messages.error_single_option_no_options": "Ne morete shraniti polja z eno možnostjo brez možnosti.",
    "messages.max_filled_as_form_description": "Za izbiro tega polja, prosim odznačite drugo polje",
    "preferences.option_integer": "Celo število",
    "preferences.option_decimal": "Decimalno število",
    "messages.tooltip_decimal_minimum_alert": "Zaradi 2 decimalnih mest je minimalna omejitev znakov za decimalna polja 3.",
    "message.form_last_update": "Zadnja posodobitev",
    "message.form_last_update_by": "Od"
};