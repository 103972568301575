import React from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import { StoreProvider } from "easy-peasy";
import store from "./store/store";
import { ThemeProvider } from "@material-ui/core";
import { MainPage } from "./shared/components/pages";
import { NotificationModalContainer } from "./feature/notification";
import { getMenuOptions } from "./feature/navigation/navigationUtils";
import MyFormContainer from "./feature/form/views/MyFormContainer";
import NewFormContainer from "./feature/form/views/NewFormContainer";
import LoadingContainer from "./feature/loading/views/LoadingContainer";
import "./App.scss";
import getBuilderRoutes from "./shared/components/pages/BuilderPage/BuilderRoutes";
import { InteractionStatus, IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "./config/msalConfig";
import { AuthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import WarningPage from "./shared/components/pages/WarningPage";
import customTheme from "./helpers/customTheme";
import { ACCESS_DENIED_ROUTE, FORM_NOT_FOUND_ROUTE } from "./shared/util/constants";

interface AppProps {
  pca: IPublicClientApplication;
}

const App = ({ pca }: AppProps) => {
  const { inProgress } = useMsal();
  const userIsAuthenticated = useIsAuthenticated();
  const { t } = useTranslation();
  if (inProgress === InteractionStatus.None && !userIsAuthenticated) {
    pca
      .acquireTokenSilent(loginRequest)
      .then()
      .catch((e) => {
        pca
          .loginRedirect(loginRequest)
          .then()
          .catch((e) => {
            console.error(e);
          });
        console.error(e);
      });
  }

  return (
    <AuthenticatedTemplate>
      <ThemeProvider theme={customTheme}>
        <StoreProviderCasted store={store}>
          <BrowserRouter>
            <MainPage menuOptions={getMenuOptions()}>
              <Routes>
                {getBuilderRoutes()}
                <Route path="/MyForms" element={<MyFormContainer />} />
                <Route path="/NewForm" element={<NewFormContainer />} />
                <Route path="/" element={<Navigate to="/MyForms" />} />
                <Route
                  path={ACCESS_DENIED_ROUTE}
                  element={
                    <WarningPage
                      title={t("messages.access_denied")}
                      text={t("messages.text_access_denied")}
                    />
                  }
                />
                <Route
                  path={FORM_NOT_FOUND_ROUTE}
                  element={
                    <WarningPage
                      title={t("messages.form_not_found")}
                      text={t("messages.text_form_not_found")}
                    />
                  }
                />
                <Route
                  path="*"
                  element={
                    <WarningPage
                      title={t("messages.page_not_found")}
                      text={t("messages.text_page_not_found")}
                    />
                  }
                />
              </Routes>
            </MainPage>
            <LoadingContainer />
            <NotificationModalContainer />
          </BrowserRouter>
        </StoreProviderCasted>
      </ThemeProvider>
    </AuthenticatedTemplate>
  );
};

type Props = StoreProvider["props"] & { children: React.ReactNode };
const StoreProviderCasted = StoreProvider as unknown as React.ComponentType<Props>;

export default withTranslation()(App);
