import "./PreferenceColumn.scss";

import {
  CatalogItem,
  FormFieldLocaleModel
} from "../../../../feature/catalog/interface/CatalogItem";
import { FormOption, ItemPreference } from "../../../../repositories/interfaces";
import React, { useEffect, useMemo, useState } from "react";
import { useStoreActions, useStoreState } from "../../../../store/hooks";

import CheckListValuePreference from "../../molecules/CheckListValuePreference";
import DescriptionPreference from "../../molecules/DescriptionPreference/DescriptionPreference";
import { ENGLISH_LANGUAGE_ID_STORED_IN_DATABASE } from "../../../util/constants";
import EquipmentMRKPreference from "../../molecules/EquipmentMRKPreference/EquipmentMRKPreference";
import FieldsInFormDescription from "../../molecules/FieldsInFormDescription/FieldsInFormDescription";
import FilterPreference from "../../molecules/FilterPreference/FilterPreference";
import FixedListItemsDropdownPreference from "../../molecules/FixedListItemsDropdownPreference/FixedListItemsDropdownPreference";
import IdPreference from "../../molecules/IdPreference/IdPreference";
import ListItemsDropdownPreference from "../../molecules/ListItemsDropdownPreference/ListItemsDropdownPreference";
import ListItemsRadioPreference from "../../molecules/ListItemsRadioPreference/ListItemsRadioPreference";
import QrOrBarCode from "../../molecules/QrOrBarCode/QrOrBarCode";
import RangeSliderPreference from "../../molecules/RangeSliderPreference/RangeSliderPreference";
import ScoreSection from "./ScoreSection/ScoreSection";
import SectionPreference from "../../molecules/SectionPreference/SectionPreference";
import SingleOptionDropdownPreference from "../../molecules/SingleOptionDropdownPreference/SingleOptionDropdownPreference";
import TitlePreference from "../../molecules/TitlePreference/TitlePreference";
import YesNoPreference from "../../molecules/YesNoPreference/YesNoPreference";
import { debounce } from "lodash";
import { floatOrIntegerOptions } from "../../molecules/SingleOptionDropdownPreference/singleOptions";
import { useTranslation } from "react-i18next";

const PreferenceColumn = () => {
  const { t } = useTranslation();
  const hasSelectedField = useStoreState((state: any) => state.fields.hasSelectedField);

  const fields = useStoreState((state: any) => state.fields.items)!;
  const sections = useStoreState((state: any) => state.sections.selectedSection);
  const selectedFieldUuid = useStoreState((state: any) => state.fields.selectedField)?.uuid;
  const selectedField = fields[sections.uuid]?.find(
    (field: CatalogItem) => field.uuid === selectedFieldUuid
  )!;
  const formRules = useStoreState((state: any) => state.rules.rules);
  const updateRule = useStoreActions((action: any) => action.rules.updateRule);  

  const selectedSection = useStoreState((state: any) => state.sections.selectedSection)!;
  const setField = useStoreActions((actions: any) => actions.fields.setField);
  const setFieldsInDescription = useStoreActions(
    (actions: any) => actions.form.setFieldsInDescription
  );

  const formDefaultLanguage = useStoreState((state: any) => state.languages.formDefaultLanguage);

  const [initialId, setInitialId] = useState(selectedField?.fieldId || "");
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (selectedField) {
      definePreferences(selectedField);

      setInitialId(selectedField.fieldId);
    }
  }, [selectedField]);

  const updateIsSaved = useStoreActions((actions: any) => actions.form.updateIsSaved);

  function definePreferences(selectedField: CatalogItem) {
    const preferences = selectedField.preferences;
    const fieldsWithoutFieldInDescription =
      selectedField.type === "pictureFile" ||
      selectedField.type === "qrbarcode" ||
      selectedField.type === "signature-qrcode";

    defineFieldsInFormDescription(preferences, selectedField, fieldsWithoutFieldInDescription);
    defineQrOrBarCode(preferences, selectedField);
    defineTitleDescription(preferences);
    defineIsNumberInteger(preferences, selectedField);
    defineCheckListValue(preferences, selectedField);
    if (
      !(preferences.find((p) => p.key === "Isnumberinteger")?.value === "true") &&
      selectedField.preferences.find((p: { key: string }) => p.key === "DecimalPlacesMin") ===
        undefined
    ) {
      selectedField.preferences.push({ key: "DecimalPlacesMin", value: "1" } as ItemPreference);
    }

    orderPreferences(preferences);
  }

  const valuePreferenceToJsonString = (value: FormOption) => {
    return JSON.stringify(value);
  };

  function orderPreferences(preferences: ItemPreference[]) {
    preferences.sort(function (a, b) {
      return preferencesDefaultOrder?.indexOf(a.key) - preferencesDefaultOrder?.indexOf(b.key);
    });
  }

  function defineIsNumberInteger(preferences: ItemPreference[], selectedField: CatalogItem) {
    if (preferences.find((p) => p.key === "Isnumberinteger") === undefined) {
      if (selectedField.type === "number")
        preferences.push({ key: "Isnumberinteger", value: "true" } as ItemPreference);
    }
  }

  function defineTitleDescription(preferences: ItemPreference[]) {
    if (preferences.find((p) => p.key === "Titledescription") === null) {
      preferences.push({ key: "Titledescription", value: "" } as ItemPreference);
    }
  }

  function defineCheckListValue(preferences: ItemPreference[], selectedField: CatalogItem) {
    const isChecklistField = selectedField.type === "check-list";
    const isEmptyValue = preferences.find((p) => p.key === "Checklistvalue") === undefined;

    if (isChecklistField && isEmptyValue)
      preferences.push({ key: "Checklistvalue", value: "1" } as ItemPreference);
  }

  function defineQrOrBarCode(preferences: ItemPreference[], selectedField: CatalogItem) {
    if (preferences.find((p) => p.key === "Qrorbarcode") === undefined) {
      if (selectedField.type === "qrbarcode")
        preferences.push({ key: "Qrorbarcode", value: "qrcode,barcode" } as ItemPreference);
    }
  }

  function defineFieldsInFormDescription(
    preferences: ItemPreference[],
    selectedField: CatalogItem,
    fieldsWithoutFieldInDescription: boolean
  ) {
    if (preferences.find((p) => p.key === "Fieldsinformdescription") === undefined) {
      if (selectedField.type === "dropdown-customer" || selectedField.type === "dropdown-equipment")
        preferences.push({ key: "Fieldsinformdescription", value: true } as ItemPreference);
      else if (!fieldsWithoutFieldInDescription) {
        preferences.push({ key: "Fieldsinformdescription", value: false } as ItemPreference);
      }
    }
  }

  const isIdAlreadyUsed = (newId: string) => {
    let idExists = false;
    Object.values(fields).forEach((fieldList) => {
      (fieldList as any[]).forEach((field: any) => {
        if (field.fieldId === newId && field.uuid !== selectedFieldUuid) {
          idExists = true;
        }
      });
    });
    return idExists;
  }

  const debounceFunction = useMemo(() => debounce((newId) => {
    if (isIdAlreadyUsed(newId)) {
      setErrorMessage(t('messages.error_type_other_id'));
      setInitialId(selectedField.fieldId);

      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
    } else {
      Object.values(formRules).forEach((rule: any) => {
        if(rule.target === selectedField.fieldId) {
            Object.assign(rule, { target: newId });
            updateRule(rule);
        }
        if(rule.agent === selectedField.fieldId) {
          Object.assign(rule, { agent: newId });
          updateRule(rule);
        }
      });
      setInitialId(newId);
      selectedField.fieldId = newId;
      setField(selectedField);
      setErrorMessage('');
    }
  }, 1000), [fields, selectedField]);

  function changeIdWithPreference(newId: string) {
    setInitialId(newId);
    debounceFunction(newId);
  }

  function changePreference(preference: ItemPreference) {
    function updateFieldsInFormDescription(oldPreference: ItemPreference) {
      oldPreference.value = preference.value;
      if (preference.value === true || preference.value === "true")
        setFieldsInDescription({ field: selectedField, action: "push" });
      if (preference.value === false || preference.value === "false")
        setFieldsInDescription({ field: selectedField, action: "pop" });
    }
    function updateTitle(oldPreference: ItemPreference) {
      let localeToUpdate = selectedField.formFieldLocales.find(
        (locale: FormFieldLocaleModel) => locale.cultureId === formDefaultLanguage?.id
      );
      if (localeToUpdate) localeToUpdate.title = preference.value;
      oldPreference.value = preference.value;
    }
    function updateDescription(oldPreference: ItemPreference) {
      let localeToUpdate = selectedField.formFieldLocales.find(
        (locale: FormFieldLocaleModel) => locale.cultureId === formDefaultLanguage?.id
      );
      if (localeToUpdate) localeToUpdate.description = preference.value;
      oldPreference.value = preference.value;
    }

    function updateOptionRadio(oldPreference: ItemPreference) {
      oldPreference.value = preference.value;
      selectedField.options = JSON.parse(preference.value);
    }

    function updateOptionDropdown(oldPreference: ItemPreference) {
      oldPreference.value = valuePreferenceToJsonString(preference.value);

      selectedField.options = JSON.parse(preference.value);
    }

    function renderDecimalPlacesPreference(oldPreference: ItemPreference) {
      if (
        oldPreference.value == "false" &&
        selectedField.preferences.find((p: { key: string }) => p.key === "DecimalPlacesMin") ===
          undefined
      ) {
        selectedField.preferences.push({ key: "DecimalPlacesMin", value: "1" } as ItemPreference);
      } else if (
        oldPreference.value == "true" &&
        selectedField.preferences.find((p: { key: string }) => p.key === "DecimalPlacesMin") !==
          undefined
      ) {
        selectedField.preferences = selectedField.preferences.filter(
          (p: { key: string }) => p.key !== "DecimalPlacesMin"
        );
      }
    }

    selectedField?.preferences.forEach((oldPreference: ItemPreference) => {
      if (oldPreference.key === preference.key) {
        switch (preference.key) {
          case "ListItemsDropdown":
            updateOptionDropdown(oldPreference);
            break;

          case "ListItemsRadio":
            updateOptionRadio(oldPreference);
            break;

          case "Title":
            updateTitle(oldPreference);
            break;

          case "Fieldsinformdescription":
            updateFieldsInFormDescription(oldPreference);
            break;

          case "Titledescription":
            updateDescription(oldPreference);
            break;

          case "Isnumberinteger":
            renderDecimalPlacesPreference(oldPreference);
            definePreferences(selectedField);
            break;

          default:
            oldPreference.value = preference.value;
        }
      }
    });
    setField(selectedField);
    setInitialId(selectedField.fieldId);
    updateIsSaved(false);
  }

  function getPreference(preference: ItemPreference, idx: number) {
    switch (preference.key) {
      case "Title":
        return (
          <TitlePreference
            key={idx}
            onChangeFunction={changePreference}
            preference={preference}
            clear={() => {
              return {};
            }}
          />
        );
      case "Mandatory":
        return (
          <YesNoPreference
            key={idx}
            changePreference={changePreference}
            preference={preference}
            title={t("preferences.required")}
          />
        );
      case "Qrorbarcode":
        return (
          <QrOrBarCode
            key={idx}
            onChangeFunction={changePreference}
            title={t("preferences.typeQrOrBarCode")}
            preference={preference}
          />
        );
      case "Fieldsinformdescription":
        return (
          <FieldsInFormDescription
            key={idx}
            selectedSectionUuid={selectedFieldUuid}
            changePreference={changePreference}
            preference={preference}
            title={t("preferences.formDescription")}
          />
        );

      case "ListItemsDropdown":
        return (
          <React.Fragment key={idx}>
            <ScoreSection />
            <ListItemsDropdownPreference
              onChangeFunction={changePreference}
              preference={preference}
              selectedField={selectedField}
            />
          </React.Fragment>
        );
      case "Filter":
        return <FilterPreference selectedField={selectedField} setField={setField} key={idx} />;
      case "ListItemsRadio":
        return (
          <React.Fragment key={idx}>
            <ScoreSection />
            <ListItemsRadioPreference
              onChangeFunction={changePreference}
              preference={preference}
              score={selectedField.score}
              cultureId={formDefaultLanguage?.id ?? ENGLISH_LANGUAGE_ID_STORED_IN_DATABASE}
            />
          </React.Fragment>
        );
      case "Automatic":
        return (
          <YesNoPreference
            key={idx}
            changePreference={changePreference}
            preference={preference}
            title={t("preferences.automatic")}
          />
        );

      case "EquipmentMRK":
        return (
          <EquipmentMRKPreference
            key={idx}
            onChangeFunction={changePreference}
            preference={preference}
          />
        );

      case "FixedListItemsDropdown":
        return <FixedListItemsDropdownPreference key={idx} preference={preference} />;

      case "Fieldid":
        return (
          <IdPreference
            key={idx}
            title={t("preferences.field_id")}
            onChangeFunction={changeIdWithPreference}
            preference={initialId}
            errorMessage={errorMessage}
          />
        );

      case "Min":
        return (
          <RangeSliderPreference
            key={idx}
            title={t("preferences.min")}
            onChangeFunction={changePreference}
            preference={preference}
            field={selectedField}
          />
        );

      case "Max":
        return (
          <RangeSliderPreference
            key={idx}
            title={t("preferences.max")}
            onChangeFunction={changePreference}
            preference={preference}
            field={selectedField}
          />
        );
      case "Checklistvalue":
        return (
          <CheckListValuePreference
            key={idx}
            title={t("preferences.checklistValue")}
            onChangeFunction={changePreference}
            preference={preference}
            optionsLength={selectedField.options.length}
          />
        );

      case "Titledescription":
        return (
          <DescriptionPreference
            key={idx}
            onChangeFunction={changePreference}
            preference={preference}
            clear={() => {
              return {};
            }}
            title={t("preferences.description")}
          />
        );
      case "Isnumberinteger":
        return (
          <SingleOptionDropdownPreference
            key={idx}
            onChangeFunction={changePreference}
            title={t("preferences.IntegerOrFloat")}
            preference={preference}
            options={floatOrIntegerOptions(t)}
          />
        );

      case "DecimalPlacesMin":
        return (
          <RangeSliderPreference
            key={idx}
            title={t("preferences.decimalPlacesMin")}
            onChangeFunction={changePreference}
            preference={preference}
            field={selectedField}
          />
        );
    }
  }
  let content = preferenceColumnContent();

  return (
    <section id="Preference-Collumn">
      <div id="PreferenceColumn-Body">{content}</div>
    </section>
  );

  function preferenceColumnContent() {
    let content;
    if (selectedField != null && hasSelectedField) {
      content = selectedField.preferences.map(getPreference);
    } else if (selectedSection != null) {
      content = <SectionPreference section={selectedSection} />;
    } else {
      content = <div className="select-field">{t("preferences.placeholder")}</div>;
    }
    return content;
  }
};

export default PreferenceColumn;

const preferencesDefaultOrder = [
  "Title",
  "Titledescription",
  "Automatic",
  "Mandatory",
  "EquipmentMRK",
  "ListItemsDropdown",
  "Checklistvalue",
  "ListItemsRadio",
  "FixedListItemsDropdown",
  "Filter",
  "Fieldid",
  "Qrorbarcode",
  "Isnumberinteger",
  "Min",
  "Max",
  "DecimalPlacesMin",
  "Fieldsinformdescription"
];
